import { throwError, throwError as observableThrowError,  Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit, Input, OnChanges, Inject, Pipe, PipeTransform, Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Project } from '../model/project';
import { User } from '../../user/model/user';
import { Router } from '@angular/router';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { switchMap, debounceTime, catchError, retry } from 'rxjs/operators';
import { AuthService } from '../../auth.service'
import { S3Service } from './s3.service'
import { UserService } from '../../user/services/user.service'
import { SocketService } from "../../socket.service";
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { uuid } from 'uuidv4';
let mime = require('mime-types');
let tus = require("tus-js-client");
import axios from 'axios';
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay});
import pLimit from 'p-limit';

import {configSettings} from '../../config';
import { start } from 'repl';
let config = new configSettings();

@Injectable()
export class ProjectService {
    constructor( 
        private router: Router, 
        private httpClient: HttpClient,  
        private authService: AuthService, 
        private userService: UserService, 
        private s3Service: S3Service,
         @Inject(DOCUMENT) private document:any, 
         private sanitizer: DomSanitizer, 
         private socketService: SocketService
    ) 
    { 
console.log("HERE IS THE URL");
console.log(config.api.project_server.get_lrws_query_items);
    }

    projectsUrl = config.api.urls.projects;
    accountsUrl = config.api.urls.accounts;
    imports = true;
    socket: any;
    importsDirty = false;
    previousImportCount = 0;

    public currentImports = {};
    //change current imports to an object so we can refer to everythnig by id

    

    shareDocument(projectId, projectName, paperId, paperName, readOnly, emailTo, message) : Observable<any>
    {
        console.log("Sharing Document");
        let url = config.api.urls.share_document;
        console.log(this.authService.getCurrentUser());
        
        let params = 
        {
            projectId: projectId, 
            emailTo: emailTo, 
            userFrom: this.authService.getCurrentUser().user_id, 
            projectName: projectName, 
            paperId: paperId,
            paperName: paperName,
            readOnly: readOnly,
            message: message
        }

        console.log(params);
        
        return this.httpClient.post<any>(url, params, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body.data || {};
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR sharing the document");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }
    
    shareProject(projectId, projectName, emailTo, message) : Observable<any>
    {
        console.log("Sharing Project");
        console.log(projectId);
        console.log(emailTo);
        let url = config.api.urls.share_project;
        console.log(this.authService.getCurrentUser());
        
        let params = 
        {
            projectId: projectId, 
            emailTo: emailTo, 
            userFrom: this.authService.getCurrentUser().user_id, 
            projectName: projectName, 
            message: message
        }

        console.log(params);
        
        return this.httpClient.post<any>(url, params, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body.data || {};
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR sharing the project");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }
    
    listUserProjects() : Observable<any>
    {
        console.log("We are in the project service list function");
        let url = config.api.urls.user_projects.replace(":id", this.authService.getCurrentUser().user_id);
        return this.httpClient.get<any>(url, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body.data || {};
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR listing the user projects");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }
    
    listAccountProjects(account_id) : Observable<Project[]>
    {
        console.log("We are in the project service list function");
        let url = config.api.urls.account_projects.replace(":id", account_id);
        return this.httpClient.get<any>(url, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body.data || {};
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR listing the account projects");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }

    listAccountProjectsTree(account_id) : Observable<Project[]>
    {
        let url = config.api.urls.account_projects.replace(":id", account_id);
        return this.httpClient.get<any>(url, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body.data || {};
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR listing the account projects tree");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }
    
    get(project_id) : Observable<Project>
    {
        console.log("We are in the project service get function");
        console.log(this.projectsUrl);
        
        return this.httpClient.post<any>(this.projectsUrl + "/" + project_id, {}, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
        
                let body = res;
                return body.data || {};
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the project");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }
    
    getDisplayTimezone(projectId) : Observable<any>
    {
        console.log("We are in the get display timezone function for project: " + projectId)
        return this.httpClient.post<any>(config.api.project_server.get_display_timezone.replace(":id", projectId), {}, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                return res.display_timezone;
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the display timezone");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }

    getUsers(project_id) : Observable<User[]>
    {
        console.log("We are in the project service getUsers function");
        
        return this.httpClient.get<any>(config.api.urls.project_users.replace(":id", project_id), this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body.data || {};
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the project users");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }

    new(account_id, name, timezone, users)
    {
        console.log("Creating a project")
        let project = {name: name, timezone: timezone, users};
        console.log(project);
        this.httpClient.post<any>(config.api.urls.account_projects.replace(":id", account_id), JSON.stringify(project), this.authService.getAuthorisedRequestOptionsHttpClient()).subscribe();
    }

    addUser(project_id: string, userEmail: string, roleId: string)
    {
        this.httpClient.post<any>(config.api.urls.project_users.replace(":id",  project_id), {"user_email": userEmail, "role_id": roleId}, this.authService.getAuthorisedRequestOptionsHttpClient()).subscribe()
    }

    eavToJson(data) {
        let intermediate = {};
        let retArr = [];
        
        for(let i = 0; i < data.length; i++)
        {
            if(!(data[i].id in intermediate)) 
            {
                intermediate[data[i].id] = {"id": data[i].id}
            }
            
            intermediate[data[i].id][data[i].name] = data[i].value
        }
        
        for(let property in intermediate)
        {
            if(intermediate.hasOwnProperty(property))
            {
                retArr.push(intermediate[property])
            }
        }
        
        return retArr;
    }

    getItems(projectId) : Observable<any>
    {
        console.log("We are in the get items function")
        console.log(projectId)
        return this.httpClient.get<any>(config.api.project_server.get_items.replace(":id", projectId), this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return this.eavToJson(body);
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the items");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }

    public getItemByBates(projectId, bates) : Observable<any>
    {
        console.log("in GetItemBates")
        return this.httpClient.post<any>(config.api.project_server.get_item_by_bates.replace(":project_id", projectId).replace(":bates", bates), {}, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body;
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the item by bates");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    } 

    getIds(projectId) : Observable<any>
    {
        console.log("In Get Ids")
        console.log(this.authService.getAuthorisedRequestOptionsHttpClient());
        return this.httpClient.post<any>(config.api.project_server.get_ids.replace(":id", projectId), {}, this.authService.getAuthorisedRequestOptionsHttpClient())
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                let body = res;
                return body;
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the ids");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }

    getLrwsQueryItems(projectId, sorting, filters, limit, offset, junkInstruction = "exclude_junk") : Observable<any>
    {
        console.log("getLRWSQueryItems");
        console.log(this.authService.getAuthorisedRequestOptionsHttpClient());
        return this.httpClient.post<any>(config.api.project_server.get_lrws_query_items.replace(":id", projectId), {sorting: sorting, filters: filters, limit: limit, offset: offset, junkInstruction: junkInstruction}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            console.log("Here's what we got from the server for getQueryItems");
            console.log(res)
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the lrws query items");
                console.log(err);
                return this.handleError(err);
            }
        ));        
    }

    getLrwsQueryItemsCount(projectId, filters, junkInstruction = "exclude_junk") : Observable<any>
    {
        console.log("getLRWSQueryItemsCount");

        return this.httpClient.post<any>(config.api.project_server.get_lrws_query_items_count.replace(":id", projectId), {filters: filters, junkInstruction: junkInstruction}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the lrws query item count");
                console.log(err);
                return this.handleError(err);
            }
        ));       
    }
    
    getItemData(projectId, itemId) : Observable<any>
    {
        return this.httpClient.get<any>(config.api.project_server.get_item.replace(":project_id", projectId).replace(":item_id", itemId), this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            console.log("getItemData Returning");
            console.log(res)
          
        //return "DATA";
        //return {'title': "Data"}
        let ret = res;
        return ret;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the item data");
                console.log(err);
                return this.handleError(err);
            }
        ));      
    }    
    
    getFamilyItems(projectId, itemId) : Observable<any>
    {
        console.log("getFamilyItems " + itemId);
        console.log(this.authService.getAuthorisedRequestOptionsHttpClient());
        return this.httpClient.post<any>(config.api.project_server.get_family_items.replace(":project_id", projectId).replace(":item_id", itemId), {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            console.log("Here's what we got from the server for getQueryItems");
            console.log(res)
            return {familyItems: res, id: itemId};
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the family items");
                console.log(err);
                return this.handleError(err);
            }
        )); 
    }
    
    getDoctypes(projectId) : Observable<any>
    {
        console.log("Getting the doctypes. Here are the sever details");
        return this.httpClient.post<any>(config.api.project_server.get_doctypes.replace(":id", projectId), {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
           let data =  res;
            let ret = [];
            for(let i = 0; i < data.length; i++)
            {
                ret.push(data[i].type);
            }
            console.log(ret);
            return ret;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the doctypes");
                console.log(err);
                return this.handleError(err);
            }
        ));     
    }

    getTags(projectId) : Observable<any>
    {
        return this.httpClient.post<any>(config.api.project_server.get_tags.replace(":id", projectId), {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
           let data =  res;
            let ret = {};
            for(let i = 0; i < data.length; i++)
            {
                ret[data[i].id] = data[i];
            }
            console.log(ret);
            return ret;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the tags");
                console.log(err);
                return this.handleError(err);
            }
        )); 
    }



    getNextStartingBates(projectId) : Observable<any>
    {
        console.log("In Get NextStartingBates")

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient()
        options.responseType = 'text';

        return this.httpClient.post<any>(config.api.project_server.get_next_starting_bates.replace(":project_id", projectId), {}, options)
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                console.log("RECEIVED BATES FROM SERVER")
                let bates = res;
                console.log(bates);
                return bates;
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the next starting bates");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }    

    getNextImportName(projectId) : Observable<any>
    {
        console.log("In Get next import name")

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient()
        options.responseType = 'text';

        return this.httpClient.post<any>(config.api.project_server.get_next_import_name.replace(":project_id", projectId), {}, options)
            // ...and calling .json() on the response to return the data
            .pipe(map((res) => {
                console.log("RECEIVED BATES FROM SERVER")
                console.log(res);
                let bates = res;
                console.log(bates);
                return bates;
            }),
            // ...deal with any errors
            catchError
            (
                (err: Response | any) =>
                {
                    console.log("ERROR getting the next import name");
                    console.log(err);
                    return this.handleError(err);
                }
            )); 
    }        

    getItemTagsReport(projectId, itemIds) : Observable<any>
    {
        let postData = {
            itemIds: itemIds
        }        
        return this.httpClient.post<any>(config.api.project_server.get_item_tags_report.replace(":id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
           let data =  res;
            return data;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the item tags report");
                console.log(err);
                return this.handleError(err);
            }
        ));    
    }
    
    tagItems(projectId, tagId, items) : Observable<any>
    {
        console.log("TagItems");
        console.log(items)
        let postData = {
            tagId: tagId,
            itemIds: items,
            userId: this.authService.getCurrentUser().user_id        
        }

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient()
        options.responseType = 'text';

        return this.httpClient.post<any>(config.api.project_server.do_tag_items.replace(":id", projectId), postData, options)
        .pipe(map((res) => {
            console.log("Successfully returned from tagging items.");
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR tagging the items");
                console.log(err);
                return this.handleError(err);
            }
        ));   
    }
    
    createTagAndTagItems(projectId, tagName, shortcutKey, fillColour, textColour, selectedIds) : Observable<any>
    {
        let postData = {
            tagName: tagName,
            fillColour: fillColour,
            textColour: textColour,
            shortcut: shortcutKey,
            itemIds: selectedIds,
            userId: this.authService.getCurrentUser().user_id
        }

        return this.httpClient.post<any>(config.api.project_server.do_tag_items_new.replace(":id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR creating tag and tagging items");
                console.log(err);
                return this.handleError(err);
            }
        ));         
    }

    editTag(projectId, tagId, title, shortcutKey, fillColour, textColour) : Observable<any>
    {
        let postData = {
            tagId: tagId,
            title: title,
            fillColour: fillColour,
            textColour: textColour,
            shortcut: shortcutKey,
        }

        return this.httpClient.post<any>(config.api.project_server.do_edit_tag.replace(":id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR editing the tags");
                console.log(err);
                return this.handleError(err);
            }
        ));       
    }

    
    untagItems(projectId, tagId, items) : Observable<any>
    {
        console.log("UntagItems");
        let postData = {
            tagId: tagId,
            itemIds: items,
            userId: this.authService.getCurrentUser().user_id
        }

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient()
        options.responseType = 'text';

        return this.httpClient.post<any>(config.api.project_server.do_untag_items.replace(":id", projectId), postData, options)
        .pipe(map((res) => {
            console.log("Successfully returned from untagging items.");
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR untagging the items");
                console.log(err);
                return this.handleError(err);
            }
        ));  
    }    
    
    deleteTag(projectId, tagId) : Observable<any>
    {
        let postData = {
            tagId: tagId,
            userId: this.authService.getCurrentUser().user_id        
        }

        return this.httpClient.post<any>(config.api.project_server.do_delete_tag.replace(":id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR deleting the tag");
                console.log(err);
                return this.handleError(err);
            }
        ));    
    }

    getRootNodes(projectId) : Observable<any>
    {
        let url = config.api.project_server.get_root_nodes.replace(":project_id", projectId);
        return this.httpClient.post<any>(url, {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the root nodes");
                console.log(err);
                return this.handleError(err);
            }
        )); 
    }

    getImports(projectId) : Observable<any>
    {
        let url = config.api.project_server.get_imports.replace(":project_id", projectId);
        return this.httpClient.post<any>(url, {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the imports");
                console.log(err);
                return this.handleError(err);
            }
        )); 
    }

    getChildNodes(projectId, itemId) : Observable<any>
    {
        console.log ("IN GET CHILD NODES");
        return this.httpClient.post<any>(config.api.project_server.get_child_nodes.replace(":project_id", projectId).replace(":item_id", itemId), {}, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the child nodes");
                console.log(err);
                return this.handleError(err);
            }
        ));   
    }

    getNodesFromSelectedItems(projectId, itemIds) : Observable<any>
    {
        let url = config.api.project_server.get_parents_and_siblings_leading_up_to_paths.replace(":project_id", projectId);
        let requestOptions = this.authService.getAuthorisedRequestOptionsHttpClient();
        requestOptions['item_ids'] = itemIds;
        return this.httpClient.post<any>(url, {}, requestOptions)
        .pipe(map((res) => {
            return res;
        }),
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR getting the nodes from the seleced items");
                console.log(err);
                return this.handleError(err);
            }
        )); 
    }
    
    getPdfData(projectId, itemId)
    {
        console.log("Here are the authoridation request options!")
        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient()
        options.responseType = 'text';
        return this.httpClient.post<any>(config.api.project_server.get_pdf_preview.replace(":project_id", projectId).replace(":item_id", itemId), {}, options)
    }

    getFile(projectId, itemId, groupName, display_filename)
    {
        console.log("projectId: " + projectId + " | itemId: " + itemId + " | groupName: " + groupName + " | display_filename: " + display_filename);

        let body = {};
        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient()
        options.responseType = 'blob';

        return this.httpClient.post<any>(config.api.project_server.get_file.replace(":project_id", projectId).replace(":item_id", itemId).replace(":group_name", groupName).replace(":display_filename", display_filename), body, options)
        .pipe(map((res) => {
            console.log("Successfully returned from getting file.");
            return res;
        }))
    }

    getHyperlink(projectId, item_id, group_name)
    {
        return this.getFileAccessUrlByItemId(projectId, item_id, group_name);
        //return config.api.project_server.hyperlink.replace(":project_id", projectId).replace(":item_id", item_id).replace(":group_name", group_name);
    }

    private async handleError (error: Response | any) 
    {
        console.log("Project Service: There has been an error");
        console.log(error);
        let errMsg: string;
        if(error instanceof Response) {
            const body = await error.json() || '';
            const err = body.error || JSON.stringify(body);
            console.log("First Part");
            errMsg = `${error.status} - ${error.statusText || ''} ${err}`;
        }
        else
        {
            console.log("Second Part");
            errMsg = error.message ? error.message : error.toString();
        }
        console.error(errMsg);
        return observableThrowError(errMsg);
    }



    getFileAccessUrlByItemId(projectId, item_id, group_name)
    {
        console.log("GetFileAccessUrlByItemId");
        console.log(projectId);
        console.log(item_id);
        console.log(group_name);
        let item_url = config.api.project_server.get_item.replace(":project_id", projectId).replace(":item_id", item_id);
        let requestOptions = this.authService.getAuthorisedRequestOptionsHttpClient();
        console.log("Posting to " + item_url);
        return this.httpClient.post<any>(item_url, {}, requestOptions)
        .pipe(
            map
            (
                (itemRes: any) => 
                {
                    console.log("In the item response");
                    console.log(itemRes);
                    console.log("This is the item we got");
                    let item = itemRes;
                    for(let i = 0; i < item.files.length; i++)
                    {
                        if(item.files[i].group_name == "preview")
                        {
                            console.log("We found the preview");
                            let file_id = item.files[i].file_id;
                            console.log(file_id);
                            let file = item.files[i];
                            let url = config.api.project_server.get_file_access_token.replace(":project_id", projectId).replace(":file_id", file.file_id);
                            return {
                                projectId: projectId,
                                file_id: file_id,
                                url: url
                            }
                        }
                    }
                    return null;
                }
            ),
            catchError(this.handleError)
        )
        .pipe(
            switchMap
            (
                (data: any, index: number) =>
                {
                    console.log("This is whats in the switchmap");
                    console.log(data);
                    console.log("We are getting the access url from " + data.url);
                    let requestOptions = this.authService.getAuthorisedRequestOptionsHttpClient();
                    console.log ("!!!!Getting the file access url  " + Date.now())
                    return of
                    (
                        this.httpClient.post<any>(data.url, {}, requestOptions)
                        .pipe
                        (
                            map
                            (
                                (res) => 
                                {
                                    console.log ("!!!!Got File Access Url " + Date.now());
                                    console.log(res);
                                    let accessToken = res.accessToken;
                                    console.log("This is what we got from GetFileAccessToken");
                                    console.log(res);
                                    let ret =  config.api.project_server.get_file_with_access_token
                                    .replace(":project_id", data.projectId)
                                    .replace(":file_id", data.file_id)
                                    .replace(":file_access_token", accessToken)
                                    .replace(":display_filename", "document");
                    
                    
                                    //Todo: this uses the original filename as the downloaed filename
                                    // .replace(":display_filename", file.filename
                                    //                                 .replace(/\//g, "_")
                                    //                                 .replace(/ /g, "_")
                                    //                                 .replace(/\?/g, "_")
                                    //                                 );
                    
                    
                                    console.log("the URL is " + ret);
                                    return of(ret);
                                }
                            )
                        ),
                        catchError(this.handleError)
                    )
                }
            ),
            catchError(this.handleError)
        )
    }


    getExtension(filename, mime)
    {
        console.log("Getting extension for " + filename + " with mime " + mime)
        if(filename.includes("."))
        {
            let ext = filename.split(".").pop();
            console.log("The ext from the filename is " + ext)
            return ext
        }
        else
        {
            let ext = this.getExtensionFromMime(mime);
            console.log("The ext from the mime is " + ext)
          return ext
        }
    }
    
    getExtensionFromMime(mime)
    {
        return mime.extension('application/octet-stream');
    }

    getDocIdFilename(item)
    {
        console.log("getDocIdFilename");
        console.log(item.attributes);
        return item.attributes["summary/bates"] + "." + this.getExtension(item.attributes["attributes/file/filename"], item.attributes["application/mime"]);
    }


    getFileAccessUrl(projectId, file, filename = "document")
    {
        console.log("GetFileAccessUrl");
        let url = config.api.project_server.get_file_access_token.replace(":project_id", projectId).replace(":file_id", file.file_id);
        let requestOptions = this.authService.getAuthorisedRequestOptionsHttpClient();
        console.log ("!!!!Getting the file access url  " + Date.now())
        return this.httpClient.post<any>(url, {}, requestOptions)
        .pipe
        (
            map
            (
                (res) => 
                {
                    console.log ("!!!!Got File Access Url " + Date.now())
                    let accessToken = res.accessToken;
                    console.log("This is what we got from GetFileAccessToken");
                    console.log(res);
                    console.log(file);
                    let ret =  config.api.project_server.get_file_with_access_token
                    .replace(":project_id", projectId)
                    .replace(":file_id", file.file_id)
                    .replace(":file_access_token", accessToken)
                    .replace(":display_filename", filename);


                    //Todo: this uses the original filename as the downloaed filename
                    // .replace(":display_filename", file.filename
                    //                                 .replace(/\//g, "_")
                    //                                 .replace(/ /g, "_")
                    //                                 .replace(/\?/g, "_")
                    //                                 );


                    console.log("the URL is " + ret);
                    return ret;
                }
            ),
            catchError(this.handleError)
        );    
    }

    public refreshCurrentImports()
    {
        this.currentImports = {};

        //setTimeout(this.refreshCurrentImports.bind(this), 5000);
    }

    
    public getCurrentImports(projectId)
    {
        let imports = [];

        for (let importId of Object.keys(this.currentImports)) 
        {
            if(this.currentImports[importId].projectId == projectId)
            {
                imports.push(this.currentImports[importId]);
            }
        }

        if(imports.length == 0 && this.previousImportCount > 0)
        {
            this.importsDirty = true;
        }
        this.previousImportCount = imports.length;
        return imports;
    }

    public clearImportsDirty()
    {
        this.importsDirty = false;
    }

    // public importFiles(files: NgxFileDropEntry[], import_name, firstBates, projectId) 
    // {
    //     let self = this;
    //     let fieldname = 'loose_files';
    //     console.log("Here are the dropped files")
    //     console.log(files);

    //     this.socket = this.socketService.getSocket(projectId);

    //     this.socket.on("import.started", (data) => {
    //         console.log("Receoved am import started statement. Here is the data");
    //         console.log(JSON.stringify(data));
    //         this.currentImports.push(data);
    //         console.log("Added an import. Here are the imports now")
    //         console.log(JSON.stringify(this.currentImports));
    //     });

    //     this.socket.on("import.finished", (data) => {
    //         for(let i = 0; i < this.currentImports.length; i++)
    //         {
    //             if(this.currentImports[i].tableName == data.tableName)
    //             {
    //                 this.currentImports.splice(i,1);
    //                 console.log("Removed an import. Here are the imports now")
    //                 console.log(JSON.stringify(this.currentImports));
    //                 this.importsDirty = true;
    //                 document.location.reload();
    //             }
    //         }
    //     });        


    //   const formData = new FormData();
    //   formData.append("starting_bates", firstBates);
    //   formData.append("import_name", import_name);

    //   let promises = [];
    //   for (const droppedFile of files) 
    //   {
    //     console.log("Here is the single file (from in the loop)")
    //     console.log(droppedFile);
   
    //     // Is it a file?
    //     if (droppedFile.fileEntry.isFile) 
    //     {
    //         console.log("We have a single file");
    //         const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;

            
    //         let promise = new Promise((resolve, reject) => {
    //             fileEntry.file
    //             (
    //                 (file: File) => 
    //                 {
    //                     console.log("We are appending the file");
    //                     console.log(file)
    //                     console.log(fieldname);
    //                     console.log(droppedFile.relativePath);
    //                     formData.append(fieldname, file, droppedFile.relativePath)
    //                     resolve();
    //                 }
    //             );    
    //         });
    //         promises.push(promise)
    //     } 
    //     else 
    //     {
    //         console.log("it's not a single file");
    //       // It was a directory (empty directories are added, otherwise only files)
    //       const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
    //       console.log(droppedFile.relativePath, fileEntry);
    //     }
    //   }

    //   Promise.all(promises)
    //   .then
    //   (
    //       values =>
    //       {


    //             console.log("Added the files, now uploading them to the server");
    //             console.log("Got the project server");
    //             let authorisedRequestOptions = this.authService.getAuthorisedRequestOptionsHttpClient();
        
    //             console.log("Here are the authorised request options for sending the loadfile")
    //             console.log(authorisedRequestOptions);
        
    //             console.log("Here is the projedct id")
    //             console.log(projectId);
    //             let url = config.api.project_server.do_ingest_loose_files.replace(":project_id", projectId);
    //             console.log("Here is the url");
    //             console.log(url);
    //             return this.httpClient.post<any>(url, formData, this.authService.getAuthorisedRequestOptionsNoContentType())
    //             .subscribe
    //             (
    //                 data =>
    //                 {
    //                     console.log("We have ingested the loadfile");
    //                 },
    //                 err =>
    //                 {
    //                     console.log("an error has occured");
    //                     console.log(err);
    //                 }
    //             );


    //       }
    //   )
    // }

    getTotalSizeInBytes(files)
    {
        let totalSize = 0;
        for (let i = 0; i < files.length; i++) {
            console.log("calculating total size. file " + i + " of " + files.length);
            console.log(files[i].sizeInBytes);
            totalSize += files[i].sizeInBytes;
        }
        console.log("Total Size: " + totalSize)
        return totalSize;
    }

    private async uploadFileNew(projectId, importId, id, filePath, file: File, token, presignedUrls) 
    {


        var configSinglePart = {
            onUploadProgress: (progressEvent) => {
              var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
              for(const importFileId in this.currentImports[importId].files)
              {
                let importFile = this.currentImports[importId].files[importFileId];
                if(importFileId == id)
                {
                    importFile.totalBytes = progressEvent.total;
                    importFile.uploadedBytes = progressEvent.loaded;
                    break;
                }
              };
              let currentImportTotalBytes = this.currentImports[importId].sizeInBytes;
              let currentImportUploadedBytes = 0;

              for(const importFileId in this.currentImports[importId].files)
              {
                let importFile = this.currentImports[importId].files[importFileId];
                currentImportUploadedBytes += importFile.uploadedBytes;
              };

              let importPercentCompleted = Math.round((currentImportUploadedBytes / currentImportTotalBytes * 100));;
              console.log("Import Percent Completed:" + importPercentCompleted);
              this.currentImports[importId].status = importPercentCompleted + "% Uploaded..."

              console.log(percentCompleted + " Percent Uploaded");
              if(progressEvent.loaded === progressEvent.total)
              {
                  return true;
              }
            }
          };

        const partSize = config.api.settings.upload_chunk_size_in_bytes;
        let self = this;
        
        async function uploadFileToPresignedUrls(presignedUrls, file) {
            console.log("Uploading a file to presigned urls");
            console.log(presignedUrls)

            if(presignedUrls.length > 1)
            {
                let completedParts = [];
                // Upload each part sequentially
                for (let i = 0; i < presignedUrls.length; i++) {
                    const start = i * partSize;
                    const end = Math.min(start + partSize, file.size);
                    const part = file.slice(start, end);
            

                    var configMultiPart = {
                        onUploadProgress: (progressEvent) => {
                          var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
                          for(const importFileId in self.currentImports[importId].files)
                          {
                            let importFile = self.currentImports[importId].files[importFileId];
                            if(importFileId == id)
                            {
                                importFile.totalBytes = importFile.sizeInBytes;
                                importFile.uploadedBytes = progressEvent.loaded + (i * partSize);
                                break;
                            }
                          };
                          let currentImportTotalBytes = self.currentImports[importId].sizeInBytes;
                          let currentImportUploadedBytes = 0;
            
                          for(const importFileId in self.currentImports[importId].files)
                          {
                            let importFile = self.currentImports[importId].files[importFileId];
                            currentImportUploadedBytes += importFile.uploadedBytes;
                          };
            
                          let importPercentCompleted = Math.round((currentImportUploadedBytes / currentImportTotalBytes * 100));;
                          console.log("Import Percent Completed:" + importPercentCompleted);
                          self.currentImports[importId].status = importPercentCompleted + "% Uploaded..."
            
                          console.log(percentCompleted + " Percent Uploaded");
                          if(progressEvent.loaded === progressEvent.total)
                          {
                              return true;
                          }
                        }
                      };


                    // Upload part and collect ETag for completion
                    console.log("We are uploading part " + (i+1))
                    const response = await uploadPartToPresignedUrl(presignedUrls[i], part, configMultiPart);
                    console.log(response)
                    let ETag  = response.headers.etag.replace(/^['"]+|['"]+$/g, ''); //comes with quotes. trim them
                    completedParts.push({ PartNumber: i + 1, ETag: ETag});
                    console.log("Part completed:")
                    console.log(completedParts[i]);
                }
            
                console.log('All parts uploaded successfully');
            
                //Complete the multipart upload
                let uploadId = presignedUrls[0].uploadId;
                let params = 
                {
                    Bucket: presignedUrls[0].fields.bucket,
                    Key: presignedUrls[0].fields.key,
                    MultipartUpload: {
                        Parts: completedParts
                    },
                    UploadId: uploadId
                }
                return completeMultipartUpload(params);
            }
            else
            {
                return uploadPartToPresignedUrl(presignedUrls[0], file, configSinglePart);
            }
        
        }
        
        async function uploadPartToPresignedUrl(presignedUrl, part, config) {
            console.log("in the upload part function")
            const formData = new FormData();

            Object.keys(presignedUrl.fields).forEach(key => {
                formData.append(key, presignedUrl.fields[key]);
            });

            formData.append('file', part);

            return axios.put(
                presignedUrl.url,
                part,
                { 
                    headers: { 'Content-Type': 'application/octet-stream' },
                    onUploadProgress: config.onUploadProgress 
                }
              )
        }
        
        async function completeMultipartUpload(params) {
            console.log("Completing multipart upload")
            let url = config.api.project_server.do_complete_multipart_upload.replace(":project_id", projectId);
            let body = params;

            try {
                const response = await self.httpClient.post<any>(url, body, self.authService.getAuthorisedRequestOptionsHttpClient()).toPromise();
                

                console.log("Here is the response for the multipart upload")

                console.log(response);
                // Check if the response status code is 200
                if (response.status === 200) {
                    // Handle successful response
                    // You can access response data using response.body
                } else {
                    // Handle error
                    console.error('Request failed with status code:', response.status);
                    // You may want to throw an error or handle it according to your application logic
                    throw new Error('Request failed with status code ' + response.status);
                }
            } catch (error) {
                // Handle error from the HTTP request
                console.error('An error occurred:', error);
                // Rethrow the error to propagate it further if necessary
                throw error;
            }
        }

        return uploadFileToPresignedUrls(presignedUrls, file); 
    }





      public createImportEntry(files: NgxFileDropEntry[], import_name, firstBates, projectId, totalSize) : Observable<any>
      {
        console.log("In the create import entry function")
        let url = config.api.project_server.do_create_import_entry.replace(":project_id", projectId);

        if(files.length == 0)
        {
            console.log("Error, import has no files");
            return;
        }

        console.log("CREATING IMPORT ENTRY!!");
        console.log("Here are the files");
        console.log(JSON.stringify(files));

        let body = 
        {
            importName: import_name,
            firstBates: firstBates,
            files: files,
            totalSize: totalSize,
            maxChunkSizeInBytes: config.api.settings.upload_chunk_size_in_bytes
        }
        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        options.responseType = 'json';
        return this.httpClient.post<any>(url, body, options)

        .pipe(
            retry(3), // Retry the request 3 times
            map((res) => {
              console.log("Successfully returned from creating import entry.");
              return res;
            }),
            catchError((error) => {
              console.error("Error occurred while creating import entry:", error);
              // You can handle the error here, log it, or rethrow it
              alert("Error importing data. Please refresh the page and try again.")
              return throwError(error); // Rethrow the error to propagate it
            })
        );
    }


    public async importFilesTus(files, import_name, firstBates, projectId, token) 
    {
        //Create an import (send firstBates and import_name), get an import id

        console.log("In import files tus")
        let self = this;
        let fieldname = 'loose_files';


        (async function(files, import_name, firstBates, projectId, token, ctx)
        {

            console.log("in the inner function")
            let totalSizeInBytes = ctx.getTotalSizeInBytes(files);
            let totalSizeInMb = Math.floor(totalSizeInBytes / 1000 / 1000);

            console.log("Calling create import entry")
            ctx.createImportEntry(files, import_name, firstBates, projectId, totalSizeInMb)
            .subscribe
            (
                async data =>
                {
                    let importId = data['importId'];
                    console.log("Got our import id")
                    console.log(importId);
                    console.log(data);

                    console.log("RECEIVED A READY FOR IMPORT MESSAGE " + Date.now())

                    console.log("We are starting a new import")
                    console.log("project id: " + projectId)
                    console.log("import id: " + importId)
                    console.log("Num files: " + files.length)
                    console.log("First bates: " + firstBates)
                    console.log("Import Name: " + import_name)
    
                    ctx.currentImports[importId] = 
                    {
                        projectId: projectId,
                        importName: import_name,
                        importId: importId,
                        files: {},
                        status: "Uploading...",
                        processingStarted: false,
                        completed: false,
                        sizeInBytes: totalSizeInBytes
                    }
                    files.forEach(file => {
                        ctx.currentImports[importId].files[file.id] = file;
                        ctx.currentImports[importId].files[file.id].totalBytes = 0;
                        ctx.currentImports[importId].files[file.id].uploadedBytes = 0;

                    });
    
                    ctx.socket = ctx.socketService.getSocket(projectId);
                    let emit_id = "project_server_emit";
                 
    
                    ctx.socket.on("import.update", (data) => {
                        console.log("Receoved am import update statement. Here is the data");
                        console.log(JSON.stringify(data));
                        console.log("Added an import. Here are the imports now")
                        console.log(JSON.stringify(ctx.currentImports));
                        if(data.updateType == "processing.started")
                        {
                            ctx.currentImports[data.importId].processingStarted = true;
                            ctx.currentImports[data.importId].status = "Importing..."
                        }
                    });

                    ctx.socket.emit(emit_id, {room: projectId, eventName:"import.update", data: {updateType: "upload.started", importId: importId}});
            
                    ctx.socket.on("import.postImportFileSyncComplete", (data) => {
                        console.log("Receoved am import FINISHED statement. Here is the data");
                        console.log(data);
      
                        ctx.currentImports[data.importId].completed = true;
                        ctx.currentImports[data.importId].status = "Complete!"
                        console.log("Removed an import. Here are the imports now")
                        console.log(JSON.stringify(ctx.currentImports));
                        ctx.importsDirty = true;
               //         document.location.reload();
    
                    });        
            
                  //get presigned-urls from response
                  const idUrlMap = {};
                  data.presignedUrls.forEach(presignedUrls => {
                    idUrlMap[presignedUrls.fileId] = presignedUrls;
                  });

                    for(const file of files)
                    {
                        file.presignedUrls = idUrlMap[file.id]
                    }
                    
                  let axiosEntries = [];

                  const limit = pLimit(100);


                  let fileUploadParams = [];
                  for (const droppedFile of files) 
                  {
                    console.log("Here is the single file (from in the loop)")
                    console.log(droppedFile);
                    let id = droppedFile.id;
               
                    // Is it a file?
                    let self = ctx
                    if (droppedFile.fileEntry.isFile) 
                    {
                        console.log("We have a single file");
                        fileUploadParams.push({projectId, importId, id, relativePath: droppedFile.relativePath, file: droppedFile.file, token, presignedUrls: droppedFile.presignedUrls});
                    } 
                    else 
                    {
                        console.log("it's not a single file");
                      // It was a directory (empty directories are added, otherwise only files)
                      const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                      console.log(droppedFile.relativePath, fileEntry);
                    }
    
                  }

                console.log("Running the limited upload");
                let uploadPromises = fileUploadParams.map(entry => {

                    // wrap the function we are calling in the limit function we defined above
                    return limit(() => {
                        console.log("Uploading file " + entry.relativePath);
                        return self.uploadFileNew(entry.projectId, entry.importId, entry.id, entry.relativePath, entry.file, entry.token, entry.presignedUrls.urls)
                        //return self.uploadFileTus(entry.projectId, entry.importId, entry.id, entry.relativePath, entry.file, entry.token, entry.presignedUrl)
                    });
                });

                  await Promise.all(uploadPromises)

                  console.log("ALL UPLOADS DONE!!")


                  let estimatedDurationInMinutes = totalSizeInMb / 1000 * 30; //30 min per gb
                  estimatedDurationInMinutes = estimatedDurationInMinutes < 5 ? 5 : estimatedDurationInMinutes;
                  estimatedDurationInMinutes = Math.round(estimatedDurationInMinutes);

                  function formatMinutes(minutes) {
                      if (minutes < 60) {
                          // For minutes less than 60, just display "X mins"
                          return `${minutes} min${minutes === 1 ? '' : 's'}`;
                      } else {
                          // For 60 minutes or more, convert to hours and minutes
                          let hours = Math.floor(minutes / 60);
                          let remainingMinutes = minutes % 60;
                          if (hours === 1) {
                              return remainingMinutes === 0 ? '1 hr' : `1 hr ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'}`;
                          } else {
                              return remainingMinutes === 0 ? `${hours} hr${hours === 1 ? '' : 's'}` : `${hours} hr${hours === 1 ? '' : 's'} ${remainingMinutes} min${remainingMinutes === 1 ? '' : 's'}`;
                          }
                      }
                  }

                  let estimatedDurationString = formatMinutes(estimatedDurationInMinutes)

                  ctx.currentImports[importId].status = "Importing (Est Duration: " + estimatedDurationString + ")"
                  let options:any = ctx.authService.getAuthorisedRequestOptionsHttpClient();
                  options.responseType = 'test';
                  ctx.httpClient.post<any>(config.api.project_server.notify_import_uploads_complete.replace(":project_id", projectId).replace(":import_id", importId), {}, options)
                  .pipe(map((res) => {
                      console.log("Successfully returned from notifying uploads complete.");
                      return res;
                  }),
                  // ...deal with any errors
                  catchError(ctx.handleError))
                  .subscribe();

                }
            )
        }
        )(files, import_name, firstBates, projectId, token, this)
    }

    restoreJunkItems(projectId, itemIds) : Observable<any>
    {
        console.log("restore junk items");
        console.log(itemIds)
        let postData = {
            itemIds: itemIds,
            userId: this.authService.getCurrentUser().user_id        
        }

        return this.httpClient.post<any>(config.api.project_server.do_restore_junk_items.replace(":project_id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            console.log("Successfully returned from restoring junk items.");
            return res;
        }),
        // ...deal with any errors
        catchError(this.handleError));        
    }

    markJunkItems(projectId, itemIds) : Observable<any>
    {
        console.log("Mark junk items");
        let postData = {
            itemIds: itemIds,
            userId: this.authService.getCurrentUser().user_id        
        }

        return this.httpClient.post<any>(config.api.project_server.do_mark_junk_items.replace(":project_id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            console.log("Successfully returned from marking junk items.");
            return res;
        }),
        // ...deal with any errors
        catchError(this.handleError));        
    }

    removeUser(project_id: any, user: any)
    {
        let body = 
        {
            project_id: project_id,
            user_id: user.id
        }
        this.httpClient.post<any>(config.api.urls.project_do_remove_user.replace(":project_id", project_id), body, this.authService.getAuthorisedRequestOptionsHttpClient()).subscribe()
    }

    downloadDataAsFile(data: any, type: string, displayFilename) {
        let blob = new Blob([data], { type: type});
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', displayFilename);
        a.click();
        a.remove();
    }

    openDummyNewTab(displayFilename)
    {
        let minHtml = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
        <meta charset="utf-8">
        <title>` + displayFilename + `</title>
        <link rel="stylesheet" href="style.css">
        <script src="script.js"></script>
        </head>
        <body>
        Loading data...
        </body>
        </html>
        `
        const winUrl = URL.createObjectURL(new Blob([minHtml], { type: "text/html" }));
        window.open(winUrl, "lrwpreview");

    }
    openDataInNewTab(data: any, type: string, displayFilename: string) {
       let blob = new File([data], displayFilename, {type: type});
       let url = window.URL.createObjectURL(blob);
       console.log("opening window");
       console.log(url);
       let newWindow = window.open(url, "lrwpreview");

       if (!newWindow || newWindow.closed || typeof newWindow.closed == 'undefined') {

        alert("Could not open link. Please check browser pop-up settings.");
        }
       console.log("should be opened by now");

    }

    exportToExcelFromFilters(project_id, lrwsFilters, title, exportId) : Observable<any>
    {
        console.log("WE ARE EXPORTING TO EXCEL")
        let body = {
            project_id: project_id,
            filters: lrwsFilters,
            title: title,
            exportId: exportId
        };

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        options.responseType = 'blob';
            return this.httpClient.post<any>(config.api.project_server.do_export_to_excel_from_filters.replace(":project_id", project_id), body, options)
            .pipe(map((res) => {
                console.log("Successfully returned from exporting to excel.");
                return res;
            }));
    }

    exportToExcelFromIds(project_id, ids, title, exportId) : Observable<any>
    {
        console.log("WE ARE EXPORTING TO EXCEL")
        console.log(ids)

        let filters = "any("
        for(let i = 0; i < ids.length; i++)
        {
            filters += "id('" + ids[i] + "'),"
        }

        filters = filters.replace(/,\s*$/, ""); //remove last comma
        filters += ")"


        let body = {
            project_id: project_id,
            filters: filters,
            title: title,
            exportId: exportId
        };

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        options.responseType = 'blob';
            return this.httpClient.post<any>(config.api.project_server.do_export_to_excel_from_filters.replace(":project_id", project_id), body, options)
            .pipe(map((res) => {
                console.log("Successfully returned from exporting to excel.");
                return res;
            }));
    }

    getExcelIndexes(project_id) : Observable<any>
    {
        let body = {
            project_id: project_id
        };

        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        options.responseType = 'json';
            return this.httpClient.post<any>(config.api.project_server.get_excel_indexes.replace(":project_id", project_id), body, options)
            .pipe(map((res) => {
                console.log("Successfully returned from getting excel indexes.");
                return res;
            }));
    }

    getExcelUrl(project_id, filename) : Observable<any>
    {
        console.log("We are in the getExcelURL project server function")
        let body = {
            project_id: project_id,
            filename: filename
        };

        console.log("Here is the body")
        console.log(body)
        console.log("In Get Excel URL")
        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        console.log("Got the options")
        options.responseType = 'text';
        console.log("Set the options type as text")
        console.log("Here are the options")
        console.log(options)
        let url = config.api.project_server.get_excel_url.replace(":project_id", project_id);
        console.log("Here is the URL that we are trying to load")
        console.log(url)
        return this.httpClient.post<any>(url, body, options)
        .pipe(map((res) => {
            console.log("Successfully returned from getting excel url.");
            return res;
        }));
    }



    getDocxExportUrl(project_id, filename) : Observable<any>
    {
        console.log("We are in the getDocxExportURL project server function")
        let body = {
            project_id: project_id,
            filename: filename
        };

        console.log("Here is the body")
        console.log(body)
        console.log("In Get Docx Export URL")
        let options:any = this.authService.getAuthorisedRequestOptionsHttpClient();
        console.log("Got the options")
        options.responseType = 'text';
        console.log("Set the options type as text")
        console.log("Here are the options")
        console.log(options)
        let url = config.api.project_server.get_docx_export_url.replace(":project_id", project_id);
        console.log("Here is the URL that we are trying to load")
        console.log(url)
        return this.httpClient.post<any>(url, body, options)
        .pipe(map((res) => {
            console.log("Successfully returned from getting docx export url.");
            return res;
        }));
    }



    downloadFromS3(url) : Observable<any>
    {

            return this.httpClient.get(url, { responseType: 'blob'})
            .pipe(map((res) => {
                console.log("Successfully returned from s3 url.");
                return res;
            }));
    }

    submitNotes(projectId, itemId, notes) : Observable<any>
    {
        let postData = {
            projectId: projectId,
            itemId: itemId,
            notes: notes
        }

        console.log("Here is the post data")
        console.log(postData)

        return this.httpClient.post<any>(config.api.project_server.do_submit_notes.replace(":project_id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR editing the tags");
                console.log(err);
                return this.handleError(err);
            }
        ));       
    }


    setIndexCategory(projectId, itemIds, category) : Observable<any>
    {
        let postData = {
            projectId: projectId,
            itemIds: itemIds,
            category: category
        }

        console.log("Here is the post data")
        console.log(postData)

        return this.httpClient.post<any>(config.api.project_server.do_set_index_category.replace(":project_id", projectId), postData, this.authService.getAuthorisedRequestOptionsHttpClient())
        .pipe(map((res) => {
            return res;
        }),
        // ...deal with any errors
        catchError
        (
            (err: Response | any) =>
            {
                console.log("ERROR setting index category");
                console.log(err);
                return this.handleError(err);
            }
        ));       
    }

}