import { Component, AfterViewInit, OnInit, OnDestroy, ElementRef, Renderer2, ViewChild, Inject, Injectable, ChangeDetectionStrategy, ChangeDetectorRef, HostListener} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Project_ServerService } from "../../../project_server.service";
import { ProjectService } from "../../services/project.service";
import { PaperService } from "../../services/paper.service";
import { AuthService } from "../../../auth.service";
import { DataGridComponent } from "../datagrid/datagrid";
import { ItemViewerComponent } from "../itemviewer/itemviewer";
import * as _ from "lodash";
import * as moment from 'moment';
import { SocketService } from "../../../socket.service";
//const uuid = require('uuidv4');
import { uuid } from 'uuidv4';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
 

import {configSettings} from '../../../config';
let config = new configSettings();

@Component({
    selector: 'view-project',
//    templateUrl: './project.view.component.editoronly.html',
    templateUrl: './project.view.component.html',
    styleUrls: ['./project.view.component.css'],
    providers: [
        Project_ServerService
    ]
})

@Injectable()
export class ProjectViewComponent implements AfterViewInit, OnInit {

    activeTab = "evidence";
    editTagInputs = {};//this.resetAddTagInputs();
    addTagInputs = {title: '', shortcut: '', fill_colour: '', text_colour: ''};//this.resetAddTagInputs();
    tagOperationAddRemove = "add";
    tagOperationItems = "selected";
    dummyEditorContent = ""; 
    dummyEditorContentInputVersion = 0; 
    queryIds: any;
    papers: any;
//    allIds: any = [];
    projectId: string;
    principalItem: any = {};
    queryLimit = 100000;
    queryOffset = 0;
    filtersDirty = false;
    filtersApplied = false;
    filterHistory = [];
    doctypes: any = [];
    tags: any = {};
    tagIds:string[] = [];
    visibleTagIds:string[] = [];
    hiddenTagIds:string[] = [];
    pendingFilters = this.getFilterTemplate();
    appliedFilters = this.getFilterTemplate();
    lrwsFilters:string;
    lastItemViewerWidth = 0;
    lastDatagridWidth = 0;
    datagridHasFocus: boolean = true;
    itemViewerHasFocus: boolean = false;
    projectName = "loading...";
    displayTimezone = "UTC";
    itemDataUpdate = Math.random();
    socket;
    newIndexName: any = "";
    useIndexCategories: boolean = true;
    setIndexCategoryName: any = "";
    shareProjectEmail: any = "";
    shareProjectMessage: any = "";
    keywordHighlightString: string = "";
    fileover;
    nextStartingBates: any = "DOC.0001.0000001";
    firstBates: any = "DOC.0001.0000001";
    nextImportName: any = "Import 1";
    importName: any = "Import 1";
    junkInstruction = "exclude_junk";
    junkMode: boolean = false;
    generatingExport = false;
    projectIsEmpty = false;
    projectIsEmptyButImporting = false;
    calculatingDroppedFiles = false;

    dataGridColumns_normal: any = [
        {width: 300, name: "Title", dataColumn: "summary/title", titleCase: false},
        {width: 140, name: "Bates Id", dataColumn: "summary/bates", titleCase: false},
        {width: 150, name: "Tags", dataColumn: "tags", titleCase: false},
        {width: 300, name: "Index Category", dataColumn: "summary/index_category", titleCase: false},
        {width: 120, name: "Date", dataColumn: "summary/date[date]", titleCase: false},
        {width: 92, name: "Type", dataColumn: "summary/type", titleCase: true},         
        {width: 200, name: "From", dataColumn: "summary/from", titleCase: false},
        {width: 200, name: "To", dataColumn: "summary/to", titleCase: false},
//        {width: 300, name: "Location", dataColumn: "attributes/file/location", titleCase: false},
        {width: 1200, name: "Path", dataColumn: "attributes/file/path", titleCase: true},
        // {width: 300, name: "Description", dataColumn: "summary/description", titleCase: false},
        // {width: 300, name: "Md5", dataColumn: "application/md5", titleCase: false},
        {width: 300, name: "Notes", dataColumn: "summary/notes", titleCase: false}
    ];

    dataGridColumns_junk: any = [
        {width: 300, name: "Title", dataColumn: "summary/title", titleCase: false},
        {width: 140, name: "Bates Id", dataColumn: "summary/bates", titleCase: false},
        {width: 250, name: "Junk Reason", dataColumn: "application/junk/reason", titleCase: true},
        {width: 1200, name: "Path", dataColumn: "attributes/file/path", titleCase: true},
        {width: 300, name: "Notes", dataColumn: "summary/notes", titleCase: false}
    ];

    dataGridColumns: any = this.dataGridColumns_normal;

    public droppedFiles = [];
    public droppedFilesSizeInBytes = 0;
    public droppedFilesSizeHumanReadable = "0kB";

    constructor(
                private projectServerService: Project_ServerService, 
                private projectService: ProjectService, 
                private paperService: PaperService, 
                private route: ActivatedRoute, 
                public datagridsplitter: ElementRef, 
                public renderer: Renderer2, 
                @Inject(DOCUMENT) public document: any, 
                public authService: AuthService,
                private ref: ChangeDetectorRef,
                private router: Router,
                private socketService: SocketService
                )
    { 
//        renderer.listen('document', 'click', (event) => {
//            this.datagridHasFocus=false;
//            console.log("Document click")
//            console.log(this.datagridHasFocus);
//            
//        })
        
        this.applyFilters();
        this.resetAddTagInputs();
    }

    hex2rgb(hex) {
        hex = hex.replace('#', '');
        return [
            parseInt(hex.substring(0,2), 16), 
            parseInt(hex.substring(2,4), 16),
            parseInt(hex.substring(4,6), 16)
        ]
    }

    ngOnInit()
    {
        this.route.params.subscribe
        (
            params => 
            {
                this.projectId = params['project_id'];

                console.log("Attempting to get the project server from project.view")
                this.projectService.get(this.projectId).subscribe(
                    response => {
                        this.projectName = response.name;
                    }
                );

                this.projectService.getDisplayTimezone(this.projectId).subscribe(
                    response => {
                        console.log("SETTING THE DISPLAY TIMEZONE!!!")
                        console.log(response);
                        this.displayTimezone = response;
                    }
                );  

                this.projectService.getImports(this.projectId).subscribe(
                    response => {
                        console.log("Getting the imports")
                        console.log("Getting the imports")
                        console.log("Getting the imports")
                        console.log("Getting the imports")
                        console.log(response);
                        this.projectIsEmpty = response.length == 0;
                    }
                );  

                this.projectService.getDoctypes(this.projectId).subscribe(
                    response => {
                        this.doctypes = response.sort();
                    }
                );                    
                
                // this.projectService.getIds(this.projectId).subscribe(
                //     response => {
                //         function copyArray(source, dest) //copy contents but keep refs intact
                //         {
                //             dest.length = 0;
                //             [].push.apply(dest, source);
                //         }                            
                        
                //         copyArray(response, this.allIds);
                //         console.log("all Ids");
                //         console.log(this.allIds);
                //     }
                // );                       
                
                this.projectService.getNextStartingBates(this.projectId).subscribe(
                    response => {
                        this.nextStartingBates = response;
                        this.firstBates = this.nextStartingBates;
                        console.log("BATES!! We got the bates: " + this.firstBates);
                    }
                );
                this.projectService.getNextImportName(this.projectId).subscribe(
                    response => {
                        this.importName = response;
                    }
                );
                            
                this.paperService.getPapersList(this.projectId).subscribe
                (
                    response => 
                    {
                        console.log("We got the papers list from the server");
                        this.papers = response;
                        console.log(this.papers);
                    }
                );


                
                this.updateTags();
                
                console.log("TRYING to get the socket from the project view component");
                this.socket = this.socketService.getSocket(this.projectId);
                var self = this;

                this.socket.on("tags.tag_added", function(tagId) {
                    console.log("A tag was added");
                    self.updateTags();
                });
                this.socket.on("tags.tag_updated", function(tagId) {
                    console.log("A tag was edited");
                    self.updateTags();
                });
                this.socket.on("tags.tag_deleted", function(tagId) {
                    console.log("A tag was deleted");
                    self.updateTags();
                });  

                this.socket.on("import.newNextStartingBates", function(startingBates) {
                    console.log("NEW STARTING BATESS RECEIVED " + startingBates)
                    self.nextStartingBates = startingBates;
                    self.firstBates = startingBates;
                });   
                this.socket.on("import.newNextImportName", function(importName) {
                    console.log("NEW Import Name RECEIVED " + importName)
                    self.nextImportName = importName;
                    self.importName = importName;
                });  
                this.socket.on("import.postImportFileSyncComplete", function() {
                    if(self.projectIsEmptyButImporting)
                    {
                        document.location.reload();
                    }
                });
                
                this.socket.on("import.finished", function() {
                    self.projectService.getNextStartingBates(self.projectId).subscribe(
                        response => {
                            self.nextStartingBates = response;
                            self.firstBates = self.nextStartingBates;
                            console.log("BATES!! We got the bates: " + self.firstBates);
                        }
                    );    
                })

                // If we happen to find an unknown tag, requests a tag update
                this.socket.on("item_tags.multiple_modified", function(itemIds) {
                    console.log("Checking to make sure we know what all these tags are");
                    var data = self.projectService.getItemTagsReport(self.projectId, itemIds).subscribe((data) => {
                        var needUpdate = false;
                            for(var i = 0; i < data.length; i++)
                            {
                                for(var j = 0; j < data[i].tag_ids.length; j++)
                                {
                                    if(self.tagIds.indexOf(data[i].tag_ids) == -1)
                                    {
                                        console.log("found an unknown tag. requesting a tag update");
                                        needUpdate = true;
                                    }
                                }
                            }
                        if(needUpdate)
                        {
                            self.updateTags();        
                        }
                    });
                });

            }
        )
        
    }

    ngOnDestroy() {
        this.socket.removeListener('export.export_ready_for_download');
    }
  
    
    ngOnAttach()
    {
//        this.datagrid.restoreScrollTop();
    }
    
    getLuma(col)
    {
        var rgb = this.hex2rgb(col);
        //return (rgb[0] + rgb[0] + rgb[1] + rgb[2] + rgb[2] + rgb[2]) / 6;
        return ((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 144)) / 1000;
    }
    
    generateTextColour(col)
    {
        return this.getLuma(col) < 180 ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.4)';
    }

    resetAddTagInputs()
    {
        var fill_colour = this.getRandomColour();
        var text_colour = this.generateTextColour(fill_colour);
        this.addTagInputs = {title: "", shortcut: "", fill_colour: fill_colour, text_colour: text_colour};
    }

    setEditTagInputs(tag)
    {
        this.editTagInputs = {title: tag.title, shortcut: tag.shortcut, fill_colour: tag.fill_colour, text_colour: tag.text_colour};
    }
    
    saveTagChanges(tagId, tagInputs)
    {
        this.projectService.editTag(this.projectId, tagId, tagInputs.title, tagInputs.shortcut, tagInputs.fill_colour, tagInputs.text_colour).subscribe();
    }
    
    deleteTag(tag)
    {
        if(confirm("Are you sure you want to delete tag " + tag.title + "?"))
        {
            this.projectService.deleteTag(this.projectId, tag.id).subscribe();
        }
    }

    addClick() {
        this.tagOperationAddRemove = "add";
    }
    removeClick() {
        this.tagOperationAddRemove = "remove";
    }
    selectedClick(event) {
        event.stopPropagation();
        this.tagOperationItems="selected";
    }
    searchResultsClick(event) {
        event.stopPropagation();
        this.tagOperationItems="searchResults";
    }    

    
    @ViewChild('datagridSplitArea', { static: true })
    datagridSplitArea: ElementRef;

    @ViewChild('itemViewerSplitArea', { static: true })
    itemViewerSplitArea: ElementRef;
    
    @ViewChild('datagrid', { static: true }) 
    datagrid:DataGridComponent;

    @ViewChild('itemviewer', { static: true }) 
    itemviewer:ItemViewerComponent;

    @ViewChild('tagControlDropdown') tagControlDropdown: ElementRef;
    

    updateTags() 
    {
        this.projectService.getTags(this.projectId).subscribe(
            response => {
                console.log("Got tags:");
                console.log(response);
                this.tags = response;
                this.tagIds = Object.keys(this.tags);
                this.visibleTagIds = [];
                this.hiddenTagIds = [];
                for(var i = 0; i < this.tagIds.length; i++)
                {
                    if(this.tags[this.tagIds[i]].visible)
                    {
                        this.visibleTagIds.push(this.tagIds[i]);
                    }
                    else
                    {
                        this.hiddenTagIds.push(this.tagIds[i]);
                    }
                }
            }
        );        
    }

    getFilterTemplate() 
    {
        return {
            date: {earliest: "", latest: ""},
            type: [],
            parties: {
                to: [],
                from: [],
                any: []
            },
            path: [],
            keywords: {'includeKeywords': [], 'includeMethod': 'Any', 'excludeKeywords': []},
            tags: {includeMethod: 'Any', includeIds: [], bannedIds: []}
        }
    }
    
    ngAfterViewInit() {
        this.document.addEventListener('mousedown', () => {
            this.setDatagridFocus(false);
            this.setItemViewerFocus(false);
        }, true);
        this.datagridSplitArea.nativeElement.addEventListener('mousedown', () => {
            this.setDatagridFocus(true);
        }, true);
        this.itemViewerSplitArea.nativeElement.addEventListener('mousedown', () => {
            this.setItemViewerFocus(true);
        }, true);
        this.lastDatagridWidth = this.datagridSplitArea.nativeElement.clientWidth;

        var myModalEl = document.getElementById('importDataModal')
        myModalEl.addEventListener('show.bs.modal', (event) => {
          this.droppedFiles.length = 0;
          this.droppedFilesSizeHumanReadable = "0kB";
          this.droppedFilesSizeInBytes = 0;
        })

        this.ref.detectChanges();
    }

    setDatagridFocus(focused: boolean)
    {
        this.datagridHasFocus = focused;
    }
    
    setItemViewerFocus(focused: boolean)
    {
        this.itemViewerHasFocus = focused;
    }

    debouncePrincipalChange = _.debounce(this.principalChange, 300, {'leading': true, 'maxWait': 300});

    principalChange(item: any)
    {
        console.log("Principal selection change:");
        console.log(item);
        this.principalItem = item;

        console.log("Getting the ids");
        // this.projectService.getIds(this.projectId).subscribe
        // (
        //     response => 
        //     {
        //                     console.log("all Ids");
        //                     this.allIds = response;
        //                     console.log(this.allIds);
        //     }
        // );                       

    }

    updateLimit()
    {
        console.log(this.queryLimit);
    }

    updateOffset()
    {
        console.log(this.queryOffset);
    }

    onFilterChange(filterType, value)
    {
        if(filterType == "keywords")
        {
            this.pendingFilters.keywords.includeMethod = value.includeMethod;
        }
        if(JSON.stringify(this.appliedFilters) !== JSON.stringify(this.pendingFilters))
        {
            this.filtersDirty = true;
        }
        else
        {
            this.filtersDirty = false;
        }
        this.applyFilters();
    }

    generateLrwsFiltersFromFilters()
    {
        var ret = "all(";
        
        function trimTrailingComma(s)
        {
            if(s.length > 0 && s[s.length -1] == ",")
            {
                return s.substring(0, s.length -1);
            }
            else 
            {
                return s;
            }
        }

        if(this.appliedFilters.date)
        {
            ret += "date(\"" + this.appliedFilters.date.earliest + "\",\"" + this.appliedFilters.date.latest + "\"),"
        }
        
        if(this.appliedFilters.keywords && this.appliedFilters.keywords.includeKeywords && this.appliedFilters.keywords.includeKeywords.length > 0)
        {
            //Include section
            if(this.appliedFilters.keywords.includeMethod.toLowerCase() == "any")
            {
                ret += "any(";
            }
            else
            {
                ret += "all(";
            }
            
            for(var i = 0; i < this.appliedFilters.keywords.includeKeywords.length; i++)
            {
                ret += "keyword(\"" + this.appliedFilters.keywords.includeKeywords[i] + "\")";
                if(i < this.appliedFilters.keywords.includeKeywords.length - 1)
                {
                    ret += ","
                }
            }
            ret += "),"
        }
        
        if(this.appliedFilters.keywords && this.appliedFilters.keywords.excludeKeywords && this.appliedFilters.keywords.excludeKeywords.length >0)
        {
            ret += "none(";
            
            for(var i = 0; i < this.appliedFilters.keywords.excludeKeywords.length; i++)
            {
                ret += "keyword(\"" + this.appliedFilters.keywords.excludeKeywords[i] + "\")";
                if(i < this.appliedFilters.keywords.excludeKeywords.length - 1)
                {
                    ret += ","
                }
            }
            ret += "),"
        }
        
        if(this.appliedFilters.type)
        {
            ret += "any(";
            for(var i = 0; i < this.appliedFilters.type.length; i++)
            {
                ret += "type(\"" + this.appliedFilters.type[i] + "\")";
                if(i < this.appliedFilters.type.length - 1)
                {
                    ret += ","
                }
            }
            ret += "),";
        }

        if(this.appliedFilters.parties)
        {
            ret += "any(";
            for(var i = 0; i < this.appliedFilters.parties.any.length; i++)
            {
                ret += "party(\"" + this.appliedFilters.parties.any[i] + "\")";
                if(i < this.appliedFilters.parties.any.length - 1)
                {
                    ret += ","
                }
            }
            ret += "),";
        }

        if(this.appliedFilters.path)
        {
            ret += "any(";
            for(var i = 0; i < this.appliedFilters.path.length; i++)
            {
                ret += "path(\"" + this.appliedFilters.path[i] + "\")";
                if(i < this.appliedFilters.path.length - 1)
                {
                    ret += ","
                }
            }
            ret += "),";
        }
        
        if(this.appliedFilters.tags)
        {
            //Include section
            var includeSection = this.appliedFilters.tags.includeMethod.toLowerCase() + "("
            var bInclude = false;
            var includeIds = this.appliedFilters.tags.includeIds || [];
            for(var i = 0; i < includeIds.length; i++)
            {
                    bInclude = true
                    includeSection += "tag(\"" + this.tags[includeIds[i]].title + "\"),";
            }
            includeSection = trimTrailingComma(includeSection);
            includeSection += "),";
            if(bInclude)
            {
                ret += includeSection;
            }
            
            //None Section
            var noneSection = "none(";
            var bNone = false;
            var bannedIds = this.appliedFilters.tags.bannedIds || [];
            for(var i = 0; i < bannedIds.length; i++)
            {
                    bNone = true;
                    noneSection += "tag(\"" + this.tags[bannedIds[i]].title + "\"),";
            }
            noneSection = trimTrailingComma(noneSection);
            noneSection += "),"
            if(bNone)
            {
                ret += noneSection;
            }
        }
        
        ret = trimTrailingComma(ret);
        
        ret += ")"
        if(ret == "all()")
        {
            ret = "";
        }

        console.log("Here is the lrwsFilters");
        console.log(ret);
        this.lrwsFilters = ret;
    }


    removeEmpty = (obj) => {
        Object.keys(obj).forEach(key => {
            
            if(key == "keywords")
            {
                if((!obj[key].includeKeywords || obj[key].includeKeywords.length == 0) && (!obj[key].excludeKeywords || obj[key].excludeKeywords.length == 0)) 
                {
                    delete obj[key];
                }
            }

           else if(key == "tags")
           {                  
                if(obj[key].includeIds.length == 0 && obj[key].bannedIds.length == 0)
                {
                    delete obj[key];
                }
           }

            else if(obj[key] && typeof obj[key] === 'object')
            {
                this.removeEmpty(obj[key]);
            }
            
            if (!obj[key] || obj[key] == null || obj[key] == '' || obj[key] == undefined || (typeof obj[key] === 'object' && !Object.keys(obj[key]).length))
            {
                delete obj[key];
            }
        });
        return obj;
    }

    
    addFiltersToHistory(cleanFilters)
    {
        var stringRepresentation = JSON.stringify(cleanFilters, null, '\t');
        this.filterHistory.unshift(
            {
                time: Date.now(),
                filtersModified: Object.keys(cleanFilters).join(", "),
                filter: stringRepresentation
            }
        );
    
        for(var i = this.filterHistory.length - 1; i>0; i--)
        {
            if(this.filterHistory[i].filter == stringRepresentation)
            {
                this.filterHistory.splice(i);
            }
        }
        
        this.filterHistory.length = Math.min(this.filterHistory.length, 9);
    }
    
    applyFilters()
    {
        this.appliedFilters = JSON.parse(JSON.stringify(this.pendingFilters));
        
        if(Object.keys(this.appliedFilters).length)
        {
            var cleanFilters = this.removeEmpty(this.appliedFilters);
            this.addFiltersToHistory(cleanFilters);
        }
        this.filtersDirty = false;
        this.projectService.importsDirty = false;
        this.filtersApplied = Object.keys(this.removeEmpty(this.appliedFilters)).length > 0;
        console.log("FILTERSAPPLIED: " + this.filtersApplied)
        console.log(this.appliedFilters)
        console.log(this.removeEmpty(this.appliedFilters))
        console.log(Object.keys(this.removeEmpty(this.appliedFilters)).length)

        this.keywordHighlightString = this.appliedFilters.keywords ? this.appliedFilters.keywords.includeKeywords.map(x=>x.replace(' ', '%20')).join(' ') : '';
        this.generateLrwsFiltersFromFilters();

    }

    clearFilters() {
        this.pendingFilters = this.getFilterTemplate();
        this.applyFilters();
        this.ref.detectChanges();
    }

    revertFilters()
    {
        this.pendingFilters = {...this.getFilterTemplate(), ...JSON.parse(JSON.stringify(this.appliedFilters))};
        this.filtersDirty = false;
    }

    onDragProgress(event)
    {
        this.lastDatagridWidth = this.datagridSplitArea.nativeElement.clientWidth;
        this.ref.detectChanges();
    }

    onDragEnd(event)
    {
        var newItemViewerWidth = event.sizes[2];
        if(newItemViewerWidth != this.lastItemViewerWidth)
        {
            // WARNING Code Copied in project.view.component.ts. Any changes need to be updated there too
            //Need to send resize event this way to satisfy IE. It is deprecated.
            if(navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
                var resizeevent = document.createEvent("UIEvents");
                resizeevent.initEvent("resize", true, false);
                window.dispatchEvent(resizeevent);
            }
            else
            {
                window.dispatchEvent(new Event('resize'));            
            }
        }
        this.lastItemViewerWidth = event.sizes[2];
    }    
    onResize(event)
    {
        
    }
    truncateFilterDisplay(obj)
    {
        Object.keys(obj).forEach(key => {
            if(obj[key] && typeof obj[key] === 'object')
            {
                this.truncateFilterDisplay(obj[key]);
            }
            if(Array.isArray(obj[key]))
            {
                var len = obj[key].length;
                if(len > 10)
                {
                    obj[key] = obj[key].slice(0,9);
                    obj[key].push("... (" + (len - 9) + " more)");
                }
            }
        });
        return obj;
    }

    currentFilterPrettifier = function() {
        return this.filterPrettifier(JSON.stringify(this.truncateFilterDisplay(this.removeEmpty(JSON.parse(JSON.stringify(this.appliedFilters)))), null, '\t'));
    }
    
    filterPrettifier = function(filter) {
        var fullString = JSON.stringify(this.truncateFilterDisplay(JSON.parse(filter)), null, '\t');
        fullString = fullString.substring(3, fullString.length - 2).replace(/\n\t/g, '\n');
        var arr = fullString.match(/[^\r\n]+/g);
        arr = arr.splice(0,23);
        if(arr.length == 23)
        {
            arr[23] = "..."
        }
        
        return arr.join("\n");
    }
    
    cleanTime = function(time) {
        return moment(time).format("YYYY-MM-DD kk:mm");
    }
    
    filterHistoryClick(filter, index) {
        if(index == 0)
        {
            this.revertFilters();
            return;
        }
        this.pendingFilters = {...this.getFilterTemplate(), ...JSON.parse(filter.filter)};
        this.filtersDirty = true;
    }
    
    datagridFocused = function()
    {
        this.datagridHasFocus = true;
        console.log("datagrid has focus");
    }
    
    datagridBlurred = function()
    {
        this.datagridHasFocus = false;
        console.log("datagrid is blurred");
    }
    
    performTagOperation = function(addRemove, items, tagId)
    {

        console.log("Datagrid has focus?" + this.datagridHasFocus)
        console.log("ItemViewer has focus?" + this.itemViewerHasFocus)
        //Are we tagging the data grid or the item viewer?

        if (this.itemViewerHasFocus) // If it's the item viewer, the do the selected subitem only
        {
            console.log("Item Viewer")
            var selectedIds:any = [this.itemviewer.subItem.id];
            if(addRemove == "add" && items == "selected")
            {
                this.projectService.tagItems(this.projectId, tagId, selectedIds).subscribe();
            }
            
            if(addRemove == "remove" && items == "selected")
            {
                this.projectService.untagItems(this.projectId, tagId, selectedIds).subscribe();
            }
            if(addRemove == "toggle" && items == "selected")
            {
                console.log("Toggling")
                var status = ""; //"on", "off", "mixed"
                for(var i = 0; i < selectedIds.length; i++)
                {
                    var item = this.datagrid.getItem(selectedIds[i]);
                    
                    if(i == 0)
                    {
                        if(item.tag_ids && item.tag_ids.indexOf(tagId) != -1)
                        {
                            status = "on";
                        }
                        else
                        {
                            status = "off";
                        }
                    }
                    else
                    {
                        if(status == "on" && (!item.tag_ids || item.tag_ids.indexOf(tagId) == -1))
                        {
                            status = "mixed";
                            break;
                        }
                        else if(status == "off" && (item.tag_ids && item.tag_ids.indexOf(tagId) != -1))
                        {
                            status = "mixed";
                            break;
                        }
                    }
                }
                console.log(status);
                if(status == "on")
                {
                    this.projectService.untagItems(this.projectId, tagId, selectedIds).subscribe();
                }
                else
                {
                    this.projectService.tagItems(this.projectId, tagId, selectedIds).subscribe();
                }
            }            
        }
        else    //Tag everything selected in the datagrid
        {
            console.log("Datagrid")
            var selectedIds = this.datagrid.subPrincipalIds;
            if(addRemove == "add" && items == "selected")
            {
                this.projectService.tagItems(this.projectId, tagId, selectedIds).subscribe();
            }
            
            if(addRemove == "remove" && items == "selected")
            {
                this.projectService.untagItems(this.projectId, tagId, selectedIds).subscribe();
            }
            if(addRemove == "toggle" && items == "selected")
            {
                console.log("Toggling")
                var status = ""; //"on", "off", "mixed"
                for(var i = 0; i < selectedIds.length; i++)
                {
                    var item = this.datagrid.getItem(selectedIds[i]);
                    
                    if(i == 0)
                    {
                        if(item.tag_ids && item.tag_ids.indexOf(tagId) != -1)
                        {
                            status = "on";
                        }
                        else
                        {
                            status = "off";
                        }
                    }
                    else
                    {
                        if(status == "on" && (!item.tag_ids || item.tag_ids.indexOf(tagId) == -1))
                        {
                            status = "mixed";
                            break;
                        }
                        else if(status == "off" && (item.tag_ids && item.tag_ids.indexOf(tagId) != -1))
                        {
                            status = "mixed";
                            break;
                        }
                    }
                }
                console.log(status);
                if(status == "on")
                {
                    this.projectService.untagItems(this.projectId, tagId, selectedIds).subscribe();
                }
                else
                {
                    this.projectService.tagItems(this.projectId, tagId, selectedIds).subscribe();
                }
            }
        }



    }

    
    generateNewPaper = function()
    {
        console.log("We are in fgenerate new paper. The paper name is " + this.newIndexName);
        console.log("The project ID for the new paper is " + this.projectId);
        var selectedIds = this.datagrid.subPrincipalIds;
        let config = {"useIndexCategories": this.useIndexCategories};
        if(selectedIds.length > 1)
        {
            this.paperService.generatePaperFromIds(this.projectId, selectedIds, this.newIndexName, config, true).subscribe();
        }
        else
        {
            //get ids from the datagrid
            var allIds = this.datagrid.allIds;
            this.paperService.generatePaperFromIds(this.projectId, allIds, this.newIndexName, config, true).subscribe();
        }
    }

    setIndexCategory = function()
    {
        var selectedIds = this.datagrid.subPrincipalIds;
        this.projectService.setIndexCategory(this.projectId, selectedIds, this.setIndexCategoryName, true).subscribe();
    }

    shareProject = function()
    {
        console.log("We are sharing the project");
        this.projectService.shareProject(this.projectId, this.projectName, this.shareProjectEmail, this.shareProjectMessage).subscribe();
    }

    @HostListener('window:keydown', ['$event'])
     keyEvent(event: KeyboardEvent) {




        if(document.activeElement.id == "notesinput")
        {
            return;
        }



        console.log("KEYEVENT!!!")
        var charCodes = {
          "48": "0",
          "49": "1",
          "50": "2",
          "51": "3",
          "52": "4",
          "53": "5",
          "54": "6",
          "55": "7",
          "56": "8",
          "57": "9",

          "65": "a",
          "66": "b", // [66]
          "67": "c", // [67]
          "68": "d", // [68]
          "69": "e", // [69]
          "70": "f", // [70]
          "71": "g", // [71]
          "72": "h", // [72]
          "73": "i", // [73]
          "74": "j", // [74]
          "75": "k", // [75]
          "76": "l", // [76]
          "77": "m", // [77]
          "78": "n", // [78]
          "79": "o", // [79]
          "80": "p", // [80]
          "81": "q", // [81]
          "82": "r", // [82]
          "83": "s", // [83]
          "84": "t", // [84]
          "85": "u", // [85]
          "86": "v", // [86]
          "87": "w", // [87]
          "88": "x", // [88]
          "89": "y", // [89]
          "90": "z"
         }
        if(this.datagridHasFocus || this.itemViewerHasFocus)
        {
           if(event.ctrlKey || event.metaKey)
           {
               console.log("Control key.");
               return;
           }
             if(charCodes.hasOwnProperty(event.keyCode.toString()))
             {
                for(var i = 0; i < this.tagIds.length; i++)
                 {
                     if(this.tags[this.tagIds[i]].shortcut ==  charCodes[event.keyCode])
                     {
                         this.performTagOperation("toggle", "selected", this.tagIds[i])
                     }
                 }
                 event.preventDefault();
             }
            else
            {
                console.log("Not a letter");
            }
        }
     }
    
    handleItemDataUpdate = function(id: any)
    {
        console.log("itemDataUpdated for " + id);
        console.log(this.principalItem.id);
        console.log(this.itemviewer.subItem.id)
        if(id == this.principalItem.id || id == this.itemviewer.subItem.id)
        {
            this.itemDataUpdate = Math.random(); //dumb trigger to get child view to update
            console.log("itemDataUpdated");
        }
    }
    
    addTag = function(tagName)
    {
        console.log(tagName);
        this.projectService.createTagAndTagItems(this.projectId, this.addTagInputs.title, this.addTagInputs.shortcut, this.addTagInputs.fill_colour, this.addTagInputs.text_colour, this.datagrid.subPrincipalIds).subscribe();
        
        //Reset inputs
        this.resetAddTagInputs();
    }

    /**
     * Converts an HSL color value to RGB. Conversion formula
     * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
     * Assumes h, s, and l are contained in the set [0, 1] and
     * returns r, g, and b in the set [0, 255].
     *
     * @param   {number}  h       The hue
     * @param   {number}  s       The saturation
     * @param   {number}  l       The lightness
     * @return  {Array}           The RGB representation
     */
    hslToRgb(h, s, l){
        var r, g, b;

        if(s == 0){
            r = g = b = l; // achromatic
        }else{
            var hue2rgb = function hue2rgb(p, q, t){
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            }

            var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            var p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }

    var rgb =  Math.round(b * 255) | (Math.round(g * 255) << 8) | (Math.round(r * 255) << 16);
      return '#' + (0x1000000 + rgb).toString(16).slice(1)

    }


    getRandomColour()
    {
        var h = Math.random()
        var s = (Math.random() * 0.25) + 0.35
        var l = (Math.random() * 0.25) + 0.35
        return this.hslToRgb(h, s, l);
    }
    tagInputChange()
    {
        if(this.addTagInputs.title.length == 0)
        {
            this.addTagInputs.shortcut = "";
        }
        else if(this.addTagInputs.title.length == 1)
        {
            if(this.addTagInputs.shortcut == "")
            {
                for(var i = 0; i < this.tagIds.length; i++)
                {
                    if(this.tags[this.tagIds[i]].shortcut && this.tags[this.tagIds[i]].shortcut.toLowerCase() == this.addTagInputs.title[0].toLowerCase())
                    {
                        return;
                    }
                }
                this.addTagInputs.shortcut = this.addTagInputs.title[0].toUpperCase();
            }
        }
    }
    tagShortcutInputChange(event)
    {
        if(this.addTagInputs.shortcut != "")
        {
            for(var i = 0; i < this.tagIds.length; i++)
            {
                if(this.tags[this.tagIds[i]].shortcut && this.tags[this.tagIds[i]].shortcut.toLowerCase() == this.addTagInputs.shortcut.toLowerCase())
                {
                    this.addTagInputs.shortcut = "";
                    event.target.value = ""
                }
            }
        }
    }

    public async droppedLooseFiles(files) 
    {
        //Combine files and this.droppedFiles without duplicates

        this.calculatingDroppedFiles = true;
        console.log("In Dropped Loose Files")
        console.log(files);

        files = files.filter((item) => item.fileEntry.isFile)
        files = files.filter((item) => !(item.fileEntry.fullPath.endsWith(".DS_Store")))

        const sizeFormatter = new Intl.NumberFormat([], {
            style: 'unit',
            unit: 'byte',
            notation: "compact",
            unitDisplay: "narrow",
        })

        for(var i = 0; i < files.length; i++)
        {
            let new_uuid = uuid();
            console.log("assigning new id: " + new_uuid)
            files[i].id = new_uuid;
            console.log("Here is the first data")

            console.log("Path Length: " + files[i].relativePath.length);
            let fileData:File = await new Promise((resolve, reject) => {
                console.log("Here is the files[i]")
                console.log(files[i])
                files[i].fileEntry.file(
                    (fileData: File) => {
                        resolve(fileData);
                    },
                    (error: DOMException) => {
                        reject(new Error("Failed to retrieve file: " + error.message)); // Reject with a custom error message
                        alert("ERROR:  Browser could not access one or more files (e.g. possibly due to long path names).\n\nSOLUTION:  Please try zipping the data and importing the zip file instead.\n\nDETAILS:  " + files[i].relativePath)
                        this.calculatingDroppedFiles = false;
                        return;
                    }
                )
              });
            console.log("Here is the file data")
            console.log(fileData);
       
            files[i].sizeHumanReadable = sizeFormatter.format(fileData.size);
            files[i].sizeInBytes = fileData.size;
            files[i].relativePath = fileData.webkitRelativePath || files[i].relativePath;
            files[i].file = fileData;
            this.droppedFilesSizeInBytes += fileData.size;
        }
        this.droppedFiles = this.droppedFiles.concat(files.filter((item) => this.droppedFiles.indexOf(item) < 0));
        this.droppedFilesSizeHumanReadable = sizeFormatter.format(this.droppedFilesSizeInBytes);

        console.log("Here are the files after filedrop");
        console.log(this.droppedFiles);
        this.calculatingDroppedFiles = false;
    }

    public importFiles(importName, startingBates, files) 
    {
        var token = this.authService.getToken();
        console.log("Here are  the files");
        console.log(files);
        console.log("Calling import files tus")
        this.projectService.importFilesTus(files, importName, startingBates, this.projectId, token);
        this.nextStartingBates = "ABC.001.001.0001";
        this.nextImportName = "New Import";

        if(this.projectIsEmpty == true)
        {
            this.projectIsEmptyButImporting = true;
        }
        return true;
    }

    public showJunk()
    {
        this.dataGridColumns = this.dataGridColumns_junk;
        this.junkInstruction = "junk_only";
        this.junkMode = true;
        this.clearFilters();
    }

    public exitJunkMode()
    {
        this.dataGridColumns = this.dataGridColumns_normal;
        this.junkInstruction = "exclude_junk";
        this.junkMode = false;
        this.clearFilters();
    }

    public refreshList()
    {
        this.datagrid.updateDisplayItems(true);
    }
    public restoreJunkItems()
    {
        var selectedIds = this.datagrid.subPrincipalIds;
        this.datagrid.showLoadingSpinner();
        this.projectService.restoreJunkItems(this.projectId, selectedIds).subscribe(
            data => {
                this.refreshList();
            }
        )
    }

    public markJunkItems()
    {
        var selectedIds = this.datagrid.subPrincipalIds;
        this.datagrid.showLoadingSpinner();
        this.projectService.markJunkItems(this.projectId, selectedIds).subscribe(
            data =>
            {
                this.refreshList();
            }
        )  
    }  
    
    public async exportToExcel()
    {

        function formatDate(s)
        {
            if(!s)
            {
                return ""
            }
            function isValidDate(d) 
            {
                return d instanceof Date;
            }
    
            function formatDate(d)
            {
                function pad(n, width, z = '0') {
                    z = z || '0';
                    n = n + '';
                    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
                  }
    
                let ret = 
                    pad(d.getFullYear(), 4) + "-" + 
                    pad(d.getMonth()+1, 2,) + "-" + 
                    pad(d.getDate(), 2) + " " + 
                    pad(d.getHours(), 2) + ":" + 
                    pad(d.getMinutes(), 2) + ":" +
                    pad(d.getSeconds(), 2);
    
                return ret;
            }
                
            let nd = new Date(s)   
            if(isValidDate(nd))
            {
                return formatDate(nd);
            }
        
            console.log("ERROR could not format date " + s)
            return "";
        }


        let self = this;
        self.generatingExport = true;


        let dateString = formatDate(new Date());

        let excelTitle = "  Lawbaa Export - Project: " + self.projectName + " - Exported at: " + dateString;
        let exportId = uuid();



        this.socket.on("export.export_ready_for_download", (data) => {

            console.log("Export ready for download!")
            data = JSON.parse(data);
            console.log(data);
            //alert("Export " + data.exportId + " is ready for download from " + data.url);

            if(exportId == data.exportId)
            {
                console.log("It's the ID we are listening for. Downloading")
                this.generatingExport = false;

                console.log("We are going to download this url now:")
                console.log(data.url);
    

                try {

                    const a = document.createElement('a');

                    // Set the href attribute to the signed URL received from the server
                    a.href = data.url;
                
                    // Optional: Set the download attribute (this is where the file will be saved)
                    a.download = 'downloaded-file';  // You can customize this filename if needed
                
                    // Append the anchor to the body (it doesn't have to be visible)
                    document.body.appendChild(a);
                
                    // Programmatically click the anchor to trigger the download
                    a.click();
                
                    // Optionally, remove the anchor element from the DOM after the click
                    document.body.removeChild(a);


                    // let result = window.open(data.url, '_blank');
                    // console.log(result)
                } catch (error) {
                    console.error('Error opening window:', error);
                }
            }
            else
            {
                console.log("Different Id. Ignoring.")
                console.log("Looking for: " + exportId)
            }
            // self.projectService.downloadFromS3(data.url).subscribe(
            //     response => {
            //         //self.projectService.downloadDataAsFile(response, "application/zip", "export.zip");
            //     }
            // )

        });





        var selectedIds = self.datagrid.subPrincipalIds;
        if(selectedIds.length > 1)
        {
            self.projectService.exportToExcelFromIds(self.projectId, selectedIds, excelTitle, exportId).subscribe(
                response => {
                    //self.generatingExport = false;
                }
            );
        }
        else
        {
            self.projectService.exportToExcelFromFilters(self.projectId, self.lrwsFilters, excelTitle, exportId).subscribe(
                response => {
                    //self.generatingExport = false;
                }
            );
        }
    }

    public closeTagsDropdown()
    {

    }
}