<div class="papersView projectPage">
    <h2 class="papersListTitle">
        Court Book Indexes
    </h2>
    <ul class="papersList">
        <li *ngFor="let paper of papers">
            <div class="papersLi">
                <a routerLink="{{paper.id}}">
                    <div class="linkDiv">
                        <img class="paperIcon" src="assets/images/icons/paper.svg"> 
                        <div class="paperTitleSubtitleHolder">
                            <div class="paperTitle">
                                {{paper.title}}
                            </div>
                            <div class="paperSubtitle">
                                Last Active: {{paper.last_active | date:'fullDate'}} ({{paper.last_active | timeago:true}})
                            </div>
                        </div>
                    </div>
                </a>
                <span class="deleteLink" (click)=deletePaper(paper)>
                    <span class="bi bi-trash"></span>
                </span>
            </div>
            <div class="downloadList">
                <ul>
                    <li *ngFor="let export of paper.exports" class="downloadItem" (click)="getDocxExport(export.filename)"><i class="bi bi-download"></i><lrw-icon [itemType]="'archive'"></lrw-icon><span>{{export.filename}}</span></li>
                </ul>
            </div>
        </li>
    </ul>    

    <h2 class="papersListTitle">
        Excel Indexes
    </h2>
    <ul class="papersList">
        <li *ngFor="let index of excelIndexes" class="papersLi downloadItem">
            <div class="fakeLink" (click)="getExcel(index.filename)">
                <div class="linkDiv">
                    <i class="bi bi-download"></i><lrw-icon [itemType]="'archive'"></lrw-icon>
                    <div class="paperTitleSubtitleHolder">
                        <div class="paperTitle">
                            {{index.filename}}
                        </div>
                        <div class="paperSubtitle">
                            Created: {{index.created | date:'fullDate'}} ({{index.created | timeago:true}})
                        </div>
                    </div>
                </div>
            </div>
            <!-- <span class="deleteLink" (click)=deletePaper(paper.id)>
                <span class="bi bi-trash"></span>
            </span> -->
        </li>
    </ul>    
</div>